// loader
window.addEventListener("DOMContentLoaded", (_event) => {
  if (document.body.classList.contains("nuit-blanche-2023")) {
    initScrollAnimation();
    overrideSocialTitle();
  }

  if (document.body.classList.contains("mon-premier-festival-2023")) {
    if (window.FontFaceSet) {
      document.fonts.ready.then(() => {
        splitTitle();
      });
    } else {
      splitTitle();
    }
  }

  if (document.body.classList.contains("nuit-blanche-2024")) {
    animateOnLoad();
  }
});

// NUIT BLANCHE 2024

function animateOnLoad() {
  const media = window.matchMedia("(max-width: 799px)").matches ? "mobile" : "desktop";

  const unverseHeader = document.querySelector(".universe--header");
  const headerImages = unverseHeader.querySelectorAll(`.header-container--${media} img`);

  Promise.all(
    Array.from(headerImages).map((img) => {
      if (img.complete) return Promise.resolve(true);
      return new Promise((resolve) => {
        img.addEventListener("load", () => resolve(true));
        img.addEventListener("error", () => resolve(false));
      });
    })
  ).then(() => {
    unverseHeader.classList.add("animate");
  });
}

// NUIT BLANCHE 2023

// Scroll animation

const isLarge = () => window.matchMedia("(min-width: 800px)").matches;
const currentFrame = (index) =>
  `https://cdn.paris.fr/paris/universes/nuit-blanche/2023/images/header_animation/nb23-tiger-frame-${index
    .toString()
    .padStart(2, "0")}.png`;

function preloadImages(frameStart, frameEnd) {
  for (let i = frameStart; i <= frameEnd; i++) {
    const img = new Image();
    img.src = currentFrame(i);
  }
}

function initImage(canvas, frameStart) {
  const context = canvas.getContext("2d");
  const img = new Image();

  img.src = currentFrame(frameStart);
  img.onload = function () {
    const sizeFactor = isLarge() ? 1.25 : 1;
    canvas.width = this.naturalWidth * sizeFactor;
    canvas.height = this.naturalHeight * sizeFactor;
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
  };

  return img;
}

function updateImage(canvas, img, index) {
  const context = canvas.getContext("2d");

  // console.debug("DRAW", index)
  img.src = currentFrame(index);
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(img, 0, 0, canvas.width, canvas.height);
}

function initScrollAnimation() {
  const html = document.documentElement;
  const title = document.querySelector(".anim-title");
  const canvas = document.querySelector(".anim-canvas");
  const layerGreen = document.querySelector(".prlx-layer-green");
  const layerGreenExtra = document.querySelector(".prlx-layer-green-extra");
  const layerLotus = document.querySelector(".prlx-layer-lotus");
  const layerPink = document.querySelector(".prlx-layer-pink");
  const layerPinkExtra = document.querySelector(".prlx-layer-pink-extra");

  const frameStart = 7;
  const frameEnd = 28;
  let lastDrawnFrame = frameStart;

  const titleStartScale = 1;
  const titleEndScale = 1.2;

  const layerGreenEndMove = 100;
  const layerLotusEndMove = 10;
  const layerPinkEndMove = 50;

  const img = initImage(canvas, frameStart);

  preloadImages(frameStart + 1, frameEnd);

  const scrollListener = (_e) => {
    const scrollTop = html.scrollTop;
    let maxScrollTop = canvas.height;
    maxScrollTop += isLarge() ? 100 : 0;
    const scrollFraction = scrollTop / maxScrollTop;

    const frameIndex = Math.min(frameEnd - 1, frameStart + Math.ceil(scrollFraction * (frameEnd - frameStart))) + 1;
    const titleScale = Math.min(titleEndScale, titleStartScale + scrollFraction * (titleEndScale - titleStartScale));
    const layerGreenMove = Math.min(layerGreenEndMove, scrollFraction * layerGreenEndMove);
    const layerLotusMove = Math.min(layerLotusEndMove, scrollFraction * layerLotusEndMove);
    const layerPinkMove = Math.min(layerPinkEndMove, scrollFraction * layerPinkEndMove);

    // canvas
    if (lastDrawnFrame !== frameIndex) {
      requestAnimationFrame(() => {
        updateImage(canvas, img, frameIndex);
      });
      lastDrawnFrame = frameIndex;
    }

    requestAnimationFrame(() => {
      // title
      title.style.transform = `scale(${titleScale})`;

      // parallax
      layerGreen.style.transform = `translate3d(0, ${layerGreenMove}px, 0)`;
      layerGreenExtra.style.transform = `translate3d(0, ${layerGreenMove}px, 0) scale(${titleScale})`;
      layerLotus.style.transform = `translate3d(0, ${layerLotusMove}px, 0)`;
      layerPink.style.transform = `translate3d(0, -${layerPinkMove}px, 0)`;
      layerPinkExtra.style.transform = `translate3d(0, -${layerPinkMove}px, 0) scale(${titleScale})`;
    });
  };

  window.addEventListener("scroll", scrollListener);
}

function overrideSocialTitle() {
  const socialSection = document.querySelector(".qfap--social");
  const socialTexts = {
    instagram: "@quefaireaparis",
    twitter: "@QueFaireAParis",
    facebook: "@NBParis",
    tiktok: "@villedeparis",
  };

  if (socialSection) {
    Object.keys(socialTexts).forEach((social) => {
      const socialTitle = socialSection.querySelector(`.has-${social}-network .qfap--social-title`);

      if (socialTitle) {
        socialTitle.textContent = socialTexts[social];
      }
    });
  }
}

// MON PREMIER FESTIVAL 2022, 2023

function splitTitle() {
  const headerElem = document.querySelector(".universe--header");
  const titleElem = headerElem.querySelector(".universe--header-title h1");
  titleElem.innerHTML = titleElem.innerHTML
    .split("<br>")
    .map((s) => s.replace(/(\S)/g, "<span>$&</span>"))
    .join("<br>");
  titleElem.hidden = false;
  headerElem.classList.add("animate");
}
